import { isTrue } from '@/utils/Common/Booleans';
import LazyGsap, {
  LazyGsapScrollTrigger,
} from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

let gsap;

function initHeroParallaxDefault(hero) {
  const heroPicture = hero?.querySelector('picture');
  const heroSpacer = hero?.querySelector('.js-ken-hero-parallax__spacer');

  if (!heroPicture || !heroSpacer) return null;

  const baseTrigger = {
    trigger: hero,
    start: 'top top',
    end: 'bottom bottom',
    scrub: true,
  };

  gsap.to(heroPicture, {
    scrollTrigger: baseTrigger,
    position: 'fixed',
    top: 0,
    zIndex: -1,
  });

  // The spacer is a transparent layer that occupies the same space as the hero image when the latter becomes fixed.
  // This allows achieving the desired parallax effect.
  gsap.set(heroSpacer, {
    height: heroPicture.offsetHeight,
    display: 'none',
  });

  gsap.to(heroSpacer, {
    scrollTrigger: baseTrigger,
    display: 'block',
  });

  return true;
}

function initHeroParallaxReveal(hero) {
  const heroPicture = hero?.querySelector('img');

  if (!heroPicture) return null;

  const baseTrigger = {
    trigger: heroPicture,
    start: 'top top',
    end: 'bottom bottom',
    scrub: true,
  };

  gsap.to(heroPicture, {
    scrollTrigger: baseTrigger,
    position: 'fixed',
    translateY: 0,
    visibility: 'visible',
  });

  return true;
}

const init = async () => {
  const els = document.querySelectorAll('.js-ken-hero-parallax');
  if (els.length < 1) return;
  ({ gsap } = await LazyGsap());
  const ScrollTrigger = await LazyGsapScrollTrigger();
  gsap.registerPlugin(ScrollTrigger);
  els.forEach(hero => {
    const isReveal = isTrue(hero.dataset.isReveal);
    if (isReveal) initHeroParallaxReveal(hero);
    else initHeroParallaxDefault(hero);
  });
};

export default () => {
  SmarteditController.addOnReprocessPageListener(init);
  window.addEventListener('resize', init);
  init();
};
